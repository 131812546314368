
header{

    .dropdown-toggle::after {
        display: none;
       
    }
    .navbar-brand{
        position: relative;
        display: block;
        min-width: 100px;
        width: clamp(100px, 20vw, 120px);
        max-width: none;
        height: auto;
        padding: 0;
        align-self: stretch;
        text-align: right;
        z-index:1001;
        margin:0;
        img{
            width: 100%;
            height: 100%!important;
        }
    }
  
    .navbar-nav{
        gap: 2rem;
    }
    .nav-link{
        display: flex;
        align-items: center;
        transition: text-shadow .2s ease-in-out, border-bottom .15s ease-in-out ; 
       
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
   
 
        padding: 15px 0!important;
        &:hover{
            border-bottom: 3px solid white;
        }
    }
    .depth-0{
        & > .nav-item{
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .nav-item.dropdown{
        height: 100%;
        display: flex;
        align-items: center;
     
        .btn-group .nav-link{
            margin: auto;
            display: flex;
            // border-bottom: 1px solid transparent!important;

        }
    }
    .dropdown-menu .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
       
    }
    .depth-0 > .nav-item > .btn-group > .dropdown-toggle-split{
        padding-left: .25rem;
        padding-right: .25rem;
    }
    .depth-1 .dropdown-toggle::after{
        margin-left: 1rem;
    }
    .dropdown-menu> li:hover > .btn-group > .dropdown-menu {
        display: block;
        
    }

    .dropdown-toggle-split, .dropdown-toggle{
        display: flex;
        align-items: center;
    }

    .btn-group{
        height: 100%;
        position: relative;
        width: 100%;
        & > .nav-link{
            height: auto;
            align-items: flex-start;
            align-self: flex-start;
            // border-bottom: 1px solid transparent!important;
            transition: none;
        }
        .dropdown-menu{
            margin-top: 0;
            margin-bottom:0;
            padding-top: 0;
            padding-bottom:0;
           
            .nav-item{
                padding: 0!important;
                width: 100%;

                .depth-2 .nav-link{
                    background-color: transparent;
                    color: #4d4d4d;
                    &:hover{
                        color: #fff;
                    }
                }
                .nav-link{
                    padding: .25rem 0!important;
                    margin: 0;
                    white-space: nowrap;
                    color:#fff;
                    font-size: 18px;
                    width: 100%;
                    display: block;
                    text-transform: uppercase;
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
            &.depth-1{
                top:100%;
                margin-top: 0;
            }
        }
        .dropdown-toggle{
            display: none;
            
        }
    }
    .menu-bg{
        position: relative;
        padding-left:8vw;
        &::after{
            z-index: -1;
            content:"";
            position: absolute;
            top:0;
            bottom:-4rem;
            left:0;
            right: 0;
            background: url('../assets/img/header-bg.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom left;

        }
    }
    .header-menu{
        list-style: none;
        align-items: center;
        gap:1.5rem;
        padding:0;
        margin:0;
        a{
            text-decoration: none;
            &:hover{
                color:$white;
            }
        }
    }
}

.header-plain{
    header{
        padding-bottom: 3rem;
        .menu-bg{
            &::after{
                display: none;
            }
            .nav-link{
                color: $primary;
            }
        }
        .navbar-brand{
            img{
                filter: invert(50%) sepia(98%) saturate(5217%) hue-rotate(340deg) brightness(109%) contrast(99%);
            }
          
        }
        .navbar-toggler-icon{
            filter: invert(50%) sepia(98%) saturate(5217%) hue-rotate(340deg) brightness(109%) contrast(99%);
        }
    }
}

.offcanvas-header{
    background-color:$primary;
    color: var(--bs-white);
}

.offcanvas-body{
    padding-left: 0;
    padding-right: 0;
    .nav-item{
        a{
            padding-left: 1rem;
            padding-right: 1rem;
            transition: background-color .15s ease-in-out!important;
        }
        & > .has-collapse-button{
            align-items: stretch;
            justify-content: space-between;
            
            & > a, button{
                transition: background-color .15s ease-in-out!important;
                border:none;
            }
            & > button{
                padding-right: 0.75rem;
            }
           
        }
        margin-bottom:0;
        &:hover, &:active{
            & > a{
                background-color:$primary;
            }
            & > .has-collapse-button{
                & > a, button{
                    background-color:$primary;
                }
            }
          
        }
      
    }
}

.offcanvas-title {
   color: #fff;
}
.modal-backdrop{
    display: none;
}

#modal_menu{
    .nav-item{
        &:hover{
            background-color: $blue;
        }
        .has-collapse-button{
            
            align-items: center;
            justify-content: space-between;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
            .nav-link{
                background-color:transparent!important;
                margin-bottom: 0;
            }
            button{
                background-color: transparent;
                border:none;
                filter: brightness(0) invert(1);
                margin-right: 2rem;
            }
            
        }
        @include media-breakpoint-up(md) {
            .navbar-nav{
                position: absolute;
                background: var(--bs-blue);
                left: calc(100%)!important;
                padding-left: 2rem;
                top:0;
                padding-top: 0;
                min-height: 50vh;
                width: 100%;
            }
        }
        
    }
    .collapse-menu {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        & > li{
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
            .nav-link{
                padding: 1rem 2rem;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
                &:hover{
                    background-color: $blue;
                }
            }
            
        }
        .has-collapse-button:not(.collapsed) button:not(.collapsed) svg {
            transform: rotate(-90deg);
        }
        .has-collapse-button button svg 
        {
            width: 18px;
        }
    }
    .menu-social-links{
        display: inline-block;
        padding:1rem 2rem;
        svg{
            width: 25px;
            height: 25px;
            aspect-ratio: 1/1;
        }
        
    }
    .menu-address{
        padding:1rem 2rem;
        color: #fff;
    }  

}
