@import "variables";




#footer{
    position: relative;
}
footer{
    .logo{
        max-width: none;
        width: clamp(150px, 20vw, 190px);
        height: auto;

    }
    .footer-contact{
        @include media-breakpoint-down(lg) {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        } 
    }

    .footer-menu{
        .row-list{
            width: auto;
            align-items: center;
        }
        .nav-item{
            display: flex;
            align-items: center;
        }
    }
    .site-map{
        ul.nav{
            gap:.5rem;
            .nav-link{
                border-bottom: 3px solid transparent;
                padding-left:5px;
                padding-right:5px;
                &:hover{
                    border-bottom: 3px solid white;
                }
            }
        }
    }
    
}

.bykeo a{
    color:inherit;
}
