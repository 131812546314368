@import "variables";

.block-wrapper{
    &> div:nth-child(odd){
        --background: #fff;
        --turn: blob-turn;
    }
    &> div:nth-child(even){
        background-color: $off-white;
        --background: #f1f1f1;
        --turn: blob-turn-reverse;
    }
    & > div:nth-child(3n+1){
        --img-fill: #ff6340;
        --time:10s;
    }
    & > div:nth-child(3n+2){
        --img-fill: #f29cfa;
        --time:14s;
    }
    & > div:nth-child(3n+3){
        --time:8s;
        --img-fill: #3645c9;
        --turn: blob-turn;
    }
}

.block-image-content{
    @include media-breakpoint-down(md) {
        .row-list{
            justify-content: center;
        }
    }
    --background:#1b1b1b;
}

.block-cta{
    a{
        overflow: hidden;
        img{
            transition: transform .5s ease-in-out;
        }
        &:hover img{
            transform: scale(1.075);
        }
    }
}

.block-sil-vacancy{
    min-height: 800px;
    .slider{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        width: 50vw!important;
        @include media-breakpoint-down(md) {
            position: relative;
            width: 100vw!important;
            padding:0 1rem;
        }
    }
    .splide__pagination{
        position: relative;
        width: 100%;
        justify-content: flex-start;
        padding: 0;
        gap: .75rem;
        
        .splide__pagination__page {
            opacity: .5;
            background-color: $primary;
            height: 13px!important;
            width: 13px!important;
            transition: all .3s ease;
            &.is-active{
                transform: none;
                opacity: 1;
                width: 40px!important;
                border-radius: 7px;
            }
        }
    }
    .card{
        min-height: 500px;
        box-shadow: 0 0 15px rgba(0,0,0,.16);
    }
}



.blob {
	width:100%;
	aspect-ratio: 1/1;
	position:relative;
    overflow: hidden;
	
	--amount:3;	
	
	img {
		background-color: var(--img-fill);
        padding: 2rem;
	}
	.svg-wrap{
        height: 100%;
        width: 100%;
        display: flex;
        transform: scale(3);
    
        overflow: hidden;
        justify-content: center;
        align-items: center;
        svg {
            width: 100%;
            height: 100%;
            position:absolute;
            animation:blob-skew calc( var(--time, 30s) * 0.5 ) linear  0s infinite, var(--turn, blob-turn) var(--time, 30s) linear infinite;
            path {
                animation: blob-scale calc( var(--time, 30s) * 0.5 ) ease-in-out 0s infinite;
        
                fill:white;
                transform-origin: center;
            }
    
        }	
    }
	
		
}


@keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blob-turn-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

@keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }
  13% {
    transform: skewY( calc( (1.8deg) * var(--amount, 2)) );
  }
  18% {
    transform: skewY( calc( (2.2deg) * var(--amount, 2)) );
  }
  24% {
    transform: skewY( calc( (2.48deg) * var(--amount, 2)) );
  }
  25% {
    transform: skewY( calc( (2.5deg) * var(--amount, 2)) );
  }
  26% {
    transform: skewY( calc( (2.48deg) * var(--amount, 2)) );
  }
  32% {
    transform: skewY( calc( (2.2deg) * var(--amount, 2)) );
  }
  37% {
    transform: skewY( calc( (1.8deg) * var(--amount, 2)) );
  }
  50% {
    transform: skewY(0deg);
  }
  63% {
    transform: skewY( calc( (-1.8deg) * var(--amount, 2)) );
  }
  68% {
    transform: skewY( calc( (-2.2deg) * var(--amount, 2)) );
  }
  74% {
    transform: skewY( calc( (-2.48deg) * var(--amount, 2)) );
  }
  75% {
    transform: skewY( calc( (-2.5deg) * var(--amount, 2)) );
  }
  76% {
    transform: skewY( calc( (-2.48deg) * var(--amount, 2)) );
  }
  82% {
    transform: skewY( calc( (-2.2deg) * var(--amount, 2)) );
  }
  87% {
    transform: skewY( calc( (-1.8deg) * var(--amount, 2)) );
  }
  100% {
    transform: skewY(0deg);
  }
}

@keyframes blob-scale {
  0% {
    transform: scaleX(.9) scaleY(1);
  }
  25% {
    transform: scaleX(.9) scaleY(.9);
  }
  50% {
    transform: scaleX(1) scaleY(.9);
  }
  75% {
    transform: scaleX(.9) scaleY(.9);
  }
  100% {
    transform: scaleX(.9) scaleY(1);
  }
}