@import "variables";

.t-page{
    #splash-wrap{
        min-height: 400px;
        .page-title {
            padding-top:5rem !important;
        }
    }
    .block-wrapper{
        & > div:nth-child(odd){
            background-color: $off-white;
        }
    }
}
.t-resources{
    #splash-wrap{
        min-height: 400px;
        height: 30vw;
        max-height: 50vh;
    }
}

.t-contact{
    #splash-wrap{
        max-height:none;
        background: none;
        @include media-breakpoint-down(lg) {
            #header-wrap{
                position: absolute;
            }
            #contact-wrap{
                min-height: 100vh;
                padding-top: 5rem;
            }

        } 
        
    }
    @include media-breakpoint-down(sm){
        .contact-map{
            .ratio-21x9{
                --bs-aspect-ratio: 75%;
            }
        }
    }
   
    .header-plain{
        header{
            padding-bottom: 3rem;
            .menu-bg{
                &::after{
                    display: none;
                }
                .nav-link{
                    color: white;
                }
            }
            .navbar-brand{
                img{
                    filter: none;
                }
              
            }
            .navbar-toggler-icon{
                filter: none;
            }
        }
    }
    .splash-graphic-wrapper{
        background: #AFAFAF;
        @include media-breakpoint-down(lg) {
            width: 100%!important;
            padding:0;
            margin:0;
        }
        .splash-graphic{
            mix-blend-mode: multiply;
        }
    }
    input, textarea {
        background: transparent;
        color:white;
    }
    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
        font-size: 24px;
    }

   .map-wrapper{
        @include media-breakpoint-down(xl) {
            --bs-aspect-ratio: 55%;
        }
        @include media-breakpoint-down(lg) {
            --bs-aspect-ratio: 100%;
        }

   }
}