
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later

@import '@splidejs/splide/css';
@import "variables";
@import "~glightbox/dist/css/glightbox.css";
@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
// Our own scss below
@import "header";
@import "splash";
@import "page";
@import "block";
@import "footer";
@import "form";

.font-primary{
    font-family: $font-primary;
    font-display: swap;
}

.font-heading{
    font-family: $font-heading;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family: $font-heading;
    font-display: swap;
}


.circle-hover{
    .overlay{
        transform: translateY(-100%);
        transition: transform .5s ease;
    }
    &:hover{
        .overlay{
            transform: translateY(0%);
        }
    }
}

.row-list{
    padding:0;
    margin:0;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    gap:1rem;
    width: 100%;

}

.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-item{
    font-weight: 400;
    &.active{
        font-weight: 700;
    } 
    .arrow-next{
        .pager-arrow-icon{
            content: url('../assets/img/pagination-right.svg');
        }
    }
    .arrow-prev{
        .pager-arrow-icon{
            content: url('../assets/img/pagination-left.svg');
        }
    }
}

.pager-arrows.disabled{
    display: none;
}

.pager-arrow-a{
    display: flex;
    gap: 1rem;
}

.link-hover{
    border-bottom: 3px solid transparent;
    transition: border .3s ease;
    font-weight: 600;
    padding-bottom: .25rem;
    &:hover, &.active{
        border-bottom: 3px solid $green;
    }
}


// //bootstrap class override
.btn-primary{
    transition: all .3s ease;
    color:white;
}


.shadow{
    box-shadow: 0 0 31px rgba(0,0,0,.16)!important;
}

.btn-link{
    display: flex;
    gap:1rem;
    align-items: center;
    border-radius: 0;
    padding: 0;
    &::after{
        transition: transform .3s ease;
        content:url("../assets/img/arrow.svg");
    }
    &.text-white{
        &::after{
            filter:brightness(0)invert(1);
            content:url("../assets/img/arrow.svg");
        }
    }
    &:hover::after{
        transform: translateX(20px);
    }
}
.btn{
    font-size: 16px!important;
    font-weight: 500;
    letter-spacing: 1px;
    &.btn-white, &.btn-primary{
        display: inline-flex;
        gap:1rem;
        align-items: center;
        &::after{
            transition: transform .3s ease;
            content:url("../assets/img/arrow.svg");
        }
        &:hover::after{
            transform: translateX(15px);
        }
    }
    &.btn-primary::after{
        filter: brightness(0)invert(1);
    }
}

.has-collapse-button .btn-link{
    border-bottom: 1px solid transparent!important;
}

blockquote{
    font-size: 2.4rem;
    color: $primary;
    font-family: $font-primary;
}

select{
    word-wrap: normal;
    background: transparent;
    padding: 0.5rem;
    border:0;
    border-bottom: 3px solid $green;
}

iframe{
    width: 100%;
}

ul{
    li{
        margin-bottom: .5rem;
    }
}

.nav-link{
    letter-spacing: 1px;
}


.accordion-flush{
    .accordion-item{
        border-bottom: solid 1px red;
        border-top: 0;
        &:last-child{
            border-bottom: solid 1px red;
        }
    }
    .accordion-button:not(.collapsed)::after{
        --bs-accordion-btn-active-icon:url('../assets/img/arrow.svg');
        --bs-accordion-btn-icon-transform: rotate(90deg);
    }
    .accordion-button{
        width: auto;
        &::after{
            margin-left: 2rem;
            --bs-accordion-btn-icon:url('../assets/img/arrow.svg');
            --bs-accordion-btn-active-icon:url('../assets/img/arrow.svg');
            --bs-accordion-btn-icon-transform: rotate(0deg);
        }
    }
}


.site-links{
    a svg{
        transition: transform .3s ease;
        &:hover{
            transform: scale(1.3);
        }
    }
    svg{
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.tb_see_more_btn{
    --tb-btn-border-radius:20px;
    --tb-btn-bg-color:var(--bs-red);
    --tb-btn-color:var(--bs-white);
    margin: 2rem auto!important;
    border-bottom: 1px solid var(--bs-red)!important;
    --tb-btn-border-color: var(--bs-red);
    --tb-btn-padding: 10px 20px;
    &:hover{
        border-bottom: 1px solid var(--bs-black)!important;
    }
}
