.carousel-caption{
    position: relative;
    left:0;
    right:0;
    hyphens: none;
    .carousel-pad {
        padding-bottom:4rem !important;
        padding-top:5rem !important;
        max-width: 500px;
    }
}

.carousel{
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: clamp(100px, 33vw, 340px);
        background-repeat: no-repeat;
        background-size: contain;
        aspect-ratio: 1/1;
        right: 0;
        bottom: clamp(-20px, -2vw, -5px);
        opacity: .3;
        z-index: 4;
    }
}

#splash-wrap{

    min-height: 600px;
    position: relative;
    overflow: hidden;
  
    background: rgba(156,156,156,.6);
    background: linear-gradient(90deg, rgba(156,156,156,.6) 0%, rgba(156,156,156,0.6) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
    .splash-graphic{
        mix-blend-mode: multiply;
    }
    @include media-breakpoint-down(md) {
        background: rgba(156,156,156,.6);
    }
}
 
.carousel-item{
    position: relative;
    &> picture > img{
        mix-blend-mode: multiply;
        pointer-events: none;
        position: absolute;
    }
    &::after{
        position: absolute;
        content:"";
        top:0;
        left:0;
        bottom:0;
        width: max(600px, 50vw);
        background: rgba(156,156,156,.6);
        z-index: -1;
        @include media-breakpoint-down(md) {
            width:100%;
        }
    }
}   
.carousel-indicators{
    bottom: 5rem;
    justify-content: start;
    gap: .75rem;
    width: 100%;
    max-width: 1800px;
    padding:0 calc(var(--bs-gutter-x) * 0.5);
    margin:0;
    [data-bs-target] {
        background-color: rgba(255, 255, 255, .5);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        box-sizing: border-box;
        border-top: 0;
        background-clip:unset;
        pointer-events:all;
        opacity: 1;
        transition: all .15s ease;
        &:hover{
            transform: scale(1.1);
            opacity: 1;
            
        }
        &.active{
            background-color: #FFFFFF;
            width: 40px;
            border-radius: 7px;
            
        }
      
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}