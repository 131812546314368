// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

$enable-negative-margins: true; 

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1467px
);

$container-padding-x:3rem;

// Theme colors
$red:#FC4242;
$blue: #182154;
$white: #ffffff;
$light: #FCFCFC;
$off-white: #F1F1F1;
$black: #58595B;
$primary: $red;


$theme-colors: (
  "primary": $primary,
  "red": $red,
  "blue": $blue,
  "off-white": $off-white,
  "black": $black,
  "white": $white,
  "light": $light,

);

// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Font Color
// $primary-text-emphasis: $primary;
// $body-emphasis-color: $primary;

$body-color: $black;
$body-bg: #FFF;


// Font Family
$font-primary: "Public Sans", sans-serif;
$font-heading: "paralucent", sans-serif;

$font-family-base: $font-primary;


// Font Sizes and Weight
$font-size-root: 20px;
$font-size-base: 1rem;
$headings-font-weight: 600;
// $body-font-weight: 700;
// $font-weight-base: 700;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 4.3rem;    //86px
$h2-font-size: 3rem;      //60px
$h3-font-size: 2.7rem;    //54px
$h4-font-size: 1.8rem;    //36px
$h5-font-size: 1.6rem;    //32px
$h6-font-size: 1.2rem;    //24px

// Lead and Small
$lead-font-size: .9rem;   //18px
$small-font-size: .8rem;   //16px

// Links
$link-color: $primary;
$link-decoration: none;


// Navbar
$navbar-padding-y:0;
$nav-link-padding-x:1.25rem;
$nav-link-padding-y:.75rem;


$nav-link-color: $white;
$nav-link-hover-color: $white;


$navbar-light-hover-color: $white;
$navbar-light-icon-color: $white;
$navbar-light-color:$white;
$navbar-light-active-color:$white;
$navbar-nav-link-padding-x:.75rem;
$navbar-toggler-border-radius:5px;

$btn-close-color: #fff;
// Dropdown
$dropdown-color: #fff;
$dropdown-bg: transparent;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $primary;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 33px;
$btn-padding-x:1.5rem;
$btn-padding-y:.5rem;
$btn-font-weight: 100;
$btn-font-size: .8rem;
$btn-color: #fff;
// $input-btn-font-family: $font-cursive;
$input-btn-font-size: 18px;
// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-focus-border-color: $primary;
// $input-focus-color:  $dark;
$input-color: #585858;
$input-font-family: $font-primary;
$input-padding-y: 1rem;

//Pagination
$pagination-color: $black;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;  
$pagination-active-border-color: transparent;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-hover-color: $primary;
$pagination-hover-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-padding-y: .175rem;
$pagination-padding-x: .05rem;

$accordion-button-active-bg: transparent;
$accordion-button-active-color: $black;
$accordion-button-color: $black;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;
$accordion-bg: transparent;

$focus-ring-width: 0;
$focus-ring-color: transparent;

$line-height-base: 1.2;

// spacer for padding and margin
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);

$modal-backdrop-bg: transparent;