label.float{
    right:0;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        padding-left:0;
    }
}
label.float, input {
    height: 50px;
    line-height: 50px;
}
.recaptcha-notice{
    margin:1rem 0;
    text-align: right;
    color:white;
    a{
        color:white;
        text-decoration: underline;
    }
}
.t-contact{
    @include media-breakpoint-down(sm) {
        form button[type=submit]{
            width: 100%!important;
        }
    }
}
.file-browse{
    display: none;
}
.file-upload-btn{
    height: 50px;
}
input, textarea{
    outline: none;
    border: 0;
    opacity: 1;
    &:focus{
        opacity: 1!important;
    }
    &:placeholder-shown{
        opacity: .3;
    }
    transition: opacity .3s ease;
}